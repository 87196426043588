import paymentMethods from './paymentMethods.json';
import backEnds from './backends.json';

export const HAS_TRANSLATIONS = Boolean(
  Number(process.env.REACT_APP_HAS_TRANSLATIONS)
);

export const CITY_NAME = process.env.REACT_APP_CITY_NAME;
export const CITY_NAME_FOR_SHOW = process.env.REACT_APP_CITY_NAME_FOR_SHOW;

export const HAS_NO_STATUS = ['rostov', 'cheboksary', 'tyumen'].some(city => {
  return CITY_NAME === city;
});

export const USE_UPPER_LOGO = Boolean(
  Number(process.env.REACT_APP_USE_UPPER_LOGO)
);
export const ABONEMENT_START = Number(process.env.REACT_APP_ABONEMENT_START);
export const IS_EASY_LOGIN = Boolean(Number(process.env.REACT_APP_EASY_AUTH));
export const IS_EASY_REGISTRATION = Boolean(
  Number(process.env.REACT_APP_EASY_REGISTRATION)
);
export const IS_EASY_RESTORE = Boolean(
  Number(process.env.REACT_APP_EASY_RESTORE)
);

export const BANNER = Number(process.env.REACT_APP_BANNER);
export const BANNER_URL = process.env.REACT_APP_BANNER_URL;
export const WITH_BIG_BANNER = Boolean(
  Number(process.env.REACT_APP_WITH_BIG_BANNER)
);
export const APP_MODAL_BANNER = Boolean(
  Number(process.env.REACT_APP_MODAL_BANNER)
);
export const APP_MODAL_BANNER_IMG_URL =
  process.env.REACT_APP_MODAL_BANNER_IMG_URL;
export const APP_MODAL_BANNER_IMG_URL_MOBILE =
    process.env.REACT_APP_MODAL_BANNER_IMG_URL_MOBILE;
export const APP_MODAL_BANNER_REDIRECT_URL =
  process.env.REACT_APP_MODAL_BANNER_REDIRECT_URL;

export const MODAL_PAYMENT_BANNER_IMG_URL = String(
  process.env.REACT_APP_MODAL_PAYMENT_BANNER_IMG_URL
);

export const FOCUS_ON_MARKER = Number(process.env.REACT_APP_FOCUS_ON_MARKER);
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const SERVER_URI = process.env.REACT_APP_SERVER_URI;
export const MAP_SOURCE = process.env.REACT_APP_MAP_SOURCE;
export const SOUTH_WEST = process.env.REACT_APP_SOUTH_WEST.split(',');
export const NORTH_EAST = process.env.REACT_APP_NORTH_EAST.split(',');
export const PARKING_OPERATOR = process.env.REACT_APP_PARKING_OPERATOR;

export const MAP_ZOOM_MAX = Number(process.env.REACT_APP_MAP_ZOOM_MAX) || 20;
export const MAP_ZOOM_MIN = Number(process.env.REACT_APP_MAP_ZOOM_MIN) || 13;

export const HAS_STATUS_IN_OPERATIONS = [].some(city => {
  return CITY_NAME === city;
});

export const FEEDBACK = Boolean(Number(process.env.REACT_APP_FEEDBACK));
export const PENALTY = Boolean(Number(process.env.REACT_APP_PENALTY));

export const RESERVATION_VIEW_ALTERNATIVE = Boolean(
  Number(process.env.REACT_APP_RESERVATION_VIEW_ALTERNATIVE)
)

export const RECAPTCHA_SITEKEY = '6Ld7Sr4hAAAAAG3KiZoI5aDnMttPR7y9emzaARQY';

export default {
  serverURI: 'https://parking.angelsit.ru',
  personalRulesUrl: '/pages/personal-data-rules',
  zone: '',
  nameCityForShow: '',
  mapSource: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png ',
  mapBounds: {
    southWest: [51.6216, 39.077285],
    northEast: [51.736132, 39.276325]
  },
  mapLifeTime: 1,
  appsLink: {
    app: 'exp://192.168.111.148:19000/?code=88765',
    playMarket: 'https://play.google.com/store',
    itunes: 'https://itunes.apple.com'
  },
  backEnds,
  CloudPayments: {
    public_key: 'pk_3c11e1d0277b44c5d70c5dab27e20'
  },
  paymentMethods,
  profile: {
    parking: {
      parkingPeriod: 15,
      planarParkingAvailable: false
    }
  }
};
